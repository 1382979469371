import styled from 'styled-components'

import Box from '../Box'

const TextBody = styled(Box)``

TextBody.defaultProps = {
  fontFamily: 'sans',
  fontSize: [1, 2],
  fontWeight: 400, // regular
  letterSpacing: '0.02em',
  lineHeight: 'body',
}

export default TextBody
